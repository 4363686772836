//CRC 校验表
var crc_table = new Array(
  0x00, 0x31, 0x62, 0x53, 0xc4, 0xf5, 0xa6, 0x97, 0xb9, 0x88, 0xdb, 0xea, 0x7d, 0x4c, 0x1f, 0x2e,
  0x43, 0x72, 0x21, 0x10, 0x87, 0xb6, 0xe5, 0xd4, 0xfa, 0xcb, 0x98, 0xa9, 0x3e, 0x0f, 0x5c, 0x6d,
  0x86, 0xb7, 0xe4, 0xd5, 0x42, 0x73, 0x20, 0x11, 0x3f, 0x0e, 0x5d, 0x6c, 0xfb, 0xca, 0x99, 0xa8,
  0xc5, 0xf4, 0xa7, 0x96, 0x01, 0x30, 0x63, 0x52, 0x7c, 0x4d, 0x1e, 0x2f, 0xb8, 0x89, 0xda, 0xeb,
  0x3d, 0x0c, 0x5f, 0x6e, 0xf9, 0xc8, 0x9b, 0xaa, 0x84, 0xb5, 0xe6, 0xd7, 0x40, 0x71, 0x22, 0x13,
  0x7e, 0x4f, 0x1c, 0x2d, 0xba, 0x8b, 0xd8, 0xe9, 0xc7, 0xf6, 0xa5, 0x94, 0x03, 0x32, 0x61, 0x50,
  0xbb, 0x8a, 0xd9, 0xe8, 0x7f, 0x4e, 0x1d, 0x2c, 0x02, 0x33, 0x60, 0x51, 0xc6, 0xf7, 0xa4, 0x95,
  0xf8, 0xc9, 0x9a, 0xab, 0x3c, 0x0d, 0x5e, 0x6f, 0x41, 0x70, 0x23, 0x12, 0x85, 0xb4, 0xe7, 0xd6,
  0x7a, 0x4b, 0x18, 0x29, 0xbe, 0x8f, 0xdc, 0xed, 0xc3, 0xf2, 0xa1, 0x90, 0x07, 0x36, 0x65, 0x54,
  0x39, 0x08, 0x5b, 0x6a, 0xfd, 0xcc, 0x9f, 0xae, 0x80, 0xb1, 0xe2, 0xd3, 0x44, 0x75, 0x26, 0x17,
  0xfc, 0xcd, 0x9e, 0xaf, 0x38, 0x09, 0x5a, 0x6b, 0x45, 0x74, 0x27, 0x16, 0x81, 0xb0, 0xe3, 0xd2,
  0xbf, 0x8e, 0xdd, 0xec, 0x7b, 0x4a, 0x19, 0x28, 0x06, 0x37, 0x64, 0x55, 0xc2, 0xf3, 0xa0, 0x91,
  0x47, 0x76, 0x25, 0x14, 0x83, 0xb2, 0xe1, 0xd0, 0xfe, 0xcf, 0x9c, 0xad, 0x3a, 0x0b, 0x58, 0x69,
  0x04, 0x35, 0x66, 0x57, 0xc0, 0xf1, 0xa2, 0x93, 0xbd, 0x8c, 0xdf, 0xee, 0x79, 0x48, 0x1b, 0x2a,
  0xc1, 0xf0, 0xa3, 0x92, 0x05, 0x34, 0x67, 0x56, 0x78, 0x49, 0x1a, 0x2b, 0xbc, 0x8d, 0xde, 0xef,
  0x82, 0xb3, 0xe0, 0xd1, 0x46, 0x77, 0x24, 0x15, 0x3b, 0x0a, 0x59, 0x68, 0xff, 0xce, 0x9d, 0xac
);

var control_speed = 3;
var cam_index = 0;
var altitude = 0;
var latitude = 0;
var longitude = 0;
var record_status = 0;
var click_mode_status = 1;
var goteuler2msg = false;
var gotstatus2 = false;
var gotversion = false;
var pitch_move = false;
var yaw_move = false;
var gimbal_mode = 1;
var IAMODE = 0;

var backlight = false;
var defog = false;
var nightmode = false;

var photo_last = 0;
var record_last = 0;
var zoom_last = 0;
var gimbal_mode_last = 0;
var camera_mode_last = 0;
var f1_last = 0;
var f2_last = 0;
var f3_last = 0;
var f4_last = 0;


var pitch_last = 0;
var yaw_last = 0;
////////////////////////////////viewpro viewlink protocol for MMC ////////////////////////////////////////////////////////
// viewlink protocol for MMC drone  2020-12
var viewlink_cmdID = {
  A1C1E1: 0X30,
  A1C1E1S1: 0X32,
  A2C2E2: 0X31,
  A2C2E2S2: 0X33,
  A1: 0X1A,
  A2: 0X2A,
  C1: 0X1C,
  C2: 0X2C,
  E1: 0x1E,
  E2: 0X2E,
  S1: 0X16,
  S2: 0X26,
  U: 0X01,
  V: 0X02,
  M_AHRS: 0XB1,
  HEART_BEAT: 0X10,
  SHAKE_HAND: 0X00,
  T1F1B1D1: 0x40,
  T2F2B2D2: 0X41,
  FW: 0XFF
}



var viewlink_cmdLEN = {
  // cmd_DATA_len + 3(len+cmdid+cs)
  A1C1E1: 17,
  A1C1E1S1: 31,
  A2C2E2: 13,
  A2C2E2S2: 18,
  T1F1B1D1: 44,
  T2F2B2D2: 52,
  A1: 12,
  A2: 5,
  C1: 5,
  C2: 6,
  E1: 6,
  E2: 8,
  S1: 17,
  S2: 8,
  U: 5,
  V: 5,
  M_AHRS: 45,
  HEART_BEAT: 4,
  SHAKE_HAND: 4,
  ////FOLLOW CMD NOT +3
  T1F1B1D1_DATA: 41,
  T2F2B2D2_DATA: 49,
  T1_DATA: 22,
  F1_DATA: 1,
  B1_DATA: 6,
  D1_DATA: 12,
  T2_DATA: 18,
  F2_DATA: 15,
  B2_DATA: 11,
  D2_DATA: 5
}


var viewlinkheadlen = 3;
var viewlinkFrameLen = { // the position of checksum
  A1C1E1: viewlink_cmdLEN.A1C1E1 + viewlinkheadlen,
  A1C1E1S1: viewlink_cmdLEN.A1C1E1S1 + viewlinkheadlen,
  A2C2E2: viewlink_cmdLEN.A2C2E2 + viewlinkheadlen,
  A2C2E2S2: viewlink_cmdLEN.A2C2E2S2 + viewlinkheadlen,
  A1: viewlink_cmdLEN.A1 + viewlinkheadlen,
  A2: viewlink_cmdLEN.A2 + viewlinkheadlen,
  C1: viewlink_cmdLEN.C1 + viewlinkheadlen,
  C2: viewlink_cmdLEN.C2 + viewlinkheadlen,
  E1: viewlink_cmdLEN.E1 + viewlinkheadlen,
  E2: viewlink_cmdLEN.E2 + viewlinkheadlen,
  S1: viewlink_cmdLEN.S1 + viewlinkheadlen,
  S2: viewlink_cmdLEN.S2 + viewlinkheadlen,
  U: viewlink_cmdLEN.U + viewlinkheadlen,
  V: viewlink_cmdLEN.V + viewlinkheadlen,
  M_AHRS: viewlink_cmdLEN.M_AHRS + viewlinkheadlen,
  HEART_BEAT: viewlink_cmdLEN.HEART_BEAT + viewlinkheadlen,
  SHAKE_HAND: viewlink_cmdLEN.SHAKE_HAND + viewlinkheadlen,

}


var viewlink_cs_pos = { // the position of checksum
  A1C1E1: viewlinkFrameLen.A1C1E1 - 1,
  A1C1E1S1: viewlinkFrameLen.A1C1E1S1 - 1,
  A2C2E2: viewlinkFrameLen.A2C2E2 - 1,
  A2C2E2S2: viewlinkFrameLen.A2C2E2S2 - 1,
  A1: viewlinkFrameLen.A1 - 1,
  A2: viewlinkFrameLen.A2 - 1,
  C1: viewlinkFrameLen.C1 - 1,
  C2: viewlinkFrameLen.C2 - 1,
  E1: viewlinkFrameLen.E1 - 1,
  E2: viewlinkFrameLen.E2 - 1,
  S1: viewlinkFrameLen.S1 - 1,
  S2: viewlinkFrameLen.S2 - 1,
  U: viewlinkFrameLen.U - 1,
  V: viewlinkFrameLen.V - 1,
  M_AHRS: viewlinkFrameLen.M_AHRS - 1,
  HEART_BEAT: viewlinkFrameLen.HEART_BEAT - 1,
  SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND - 1,

}


var mmcAddLen = 2; //(len+cs)
var mmcCmdLen = { // the position of checksum
  A1C1E1: viewlinkFrameLen.A1C1E1 + mmcAddLen,
  A1C1E1S1: viewlinkFrameLen.A1C1E1S1 + mmcAddLen,
  A2C2E2: viewlinkFrameLen.A2C2E2 + mmcAddLen,
  A2C2E2S2: viewlinkFrameLen.A2C2E2S2 + mmcAddLen,
  A1: viewlinkFrameLen.A1 + mmcAddLen,
  A2: viewlinkFrameLen.A2 + mmcAddLen,
  C1: viewlinkFrameLen.C1 + mmcAddLen,
  C2: viewlinkFrameLen.C2 + mmcAddLen,
  E1: viewlinkFrameLen.E1 + mmcAddLen,
  E2: viewlinkFrameLen.E2 + mmcAddLen,
  S1: viewlinkFrameLen.S1 + mmcAddLen,
  S2: viewlinkFrameLen.S2 + mmcAddLen,
  U: viewlinkFrameLen.U + mmcAddLen,
  V: viewlinkFrameLen.V + mmcAddLen,
  M_AHRS: viewlinkFrameLen.M_AHRS + mmcAddLen,
  HEART_BEAT: viewlinkFrameLen.HEART_BEAT + mmcAddLen,
  SHAKE_HAND: viewlinkFrameLen.SHAKE_HAND + mmcAddLen,
}



var mmcHeadLen = 2;
var mmcFrameLen = { // the position of checksum
  A1C1E1: mmcCmdLen.A1C1E1 + mmcHeadLen,
  A1C1E1S1: mmcCmdLen.A1C1E1S1 + mmcHeadLen,
  A2C2E2: mmcCmdLen.A2C2E2 + mmcHeadLen,
  A2C2E2S2: mmcCmdLen.A2C2E2S2 + mmcHeadLen,
  A1: mmcCmdLen.A1 + mmcHeadLen,
  A2: mmcCmdLen.A2 + mmcHeadLen,
  C1: mmcCmdLen.C1 + mmcHeadLen,
  C2: mmcCmdLen.C2 + mmcHeadLen,
  E1: mmcCmdLen.E1 + mmcHeadLen,
  E2: mmcCmdLen.E2 + mmcHeadLen,
  S1: mmcCmdLen.S1 + mmcHeadLen,
  S2: mmcCmdLen.S2 + mmcHeadLen,
  U: mmcCmdLen.U + mmcHeadLen,
  V: mmcCmdLen.V + mmcHeadLen,
  M_AHRS: mmcCmdLen.M_AHRS + mmcHeadLen,
  HEART_BEAT: mmcCmdLen.HEART_BEAT + mmcHeadLen,
  SHAKE_HAND: mmcCmdLen.SHAKE_HAND + mmcHeadLen,
}

var mmc_cs_pos = { // the position of checksum
  A1C1E1: mmcFrameLen.A1C1E1 - 1,
  A1C1E1S1: mmcFrameLen.A1C1E1S1 - 1,
  A2C2E2: mmcFrameLen.A2C2E2 - 1,
  A2C2E2S2: mmcFrameLen.A2C2E2S2 - 1,
  A1: mmcFrameLen.A1 - 1,
  A2: mmcFrameLen.A2 - 1,
  C1: mmcFrameLen.C1 - 1,
  C2: mmcFrameLen.C2 - 1,
  E1: mmcFrameLen.E1 - 1,
  E2: mmcFrameLen.E2 - 1,
  S1: mmcFrameLen.S1 - 1,
  S2: mmcFrameLen.S2 - 1,
  U: mmcFrameLen.U - 1,
  V: mmcFrameLen.V - 1,
  M_AHRS: mmcFrameLen.M_AHRS - 1,
  HEART_BEAT: mmcFrameLen.HEART_BEAT - 1,
  SHAKE_HAND: mmcFrameLen.SHAKE_HAND - 1,
}

var FW_data_len = 0;
var viewlink_cmdLEN_FW = FW_data_len + 3;
var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
var viewlink_cs_pos_FW = viewlinkFrameLen_FW - 1;
var mmcCmdLen_FW = viewlinkFrameLen_FW + mmcAddLen;
var mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
var mmc_cs_pos_FW = mmcFrameLen_FW - 1;

var viewlink_cmd_ID_LEN = {
  cmdID: viewlink_cmdID.A1,
  cmdLEN: viewlink_cmdLEN.A1
}
// A1 
var A1_SERVO_STATUS = {
  motoroff: 0x00,
  manualcontrol: 0x01,
  followyaw: 0x03,
  homeposition: 0x04,
  trackmode: 0x06,
  turntorelativeangle: 0x09,
  lockyaw: 0x0a,
  turntoframeangle: 0x0b,
  rcmode: 0x0d,
  movetofinger: 0x0E,
  noaction: 0x0f
}
var A1_PARAM_INT16 = {
  PARAM1: 0x0000,
  PARAM2: 0x0000,
  PARAM3: 0x0000,
  PARAM4: 0x0000
}
var A1_CMD = {
  server_status: A1_SERVO_STATUS.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  param2: A1_PARAM_INT16.PARAM2,
  param3: A1_PARAM_INT16.PARAM3,
  param4: A1_PARAM_INT16.PARAM4
}
// var viewlink_A2_servo = {
//   noAction:0,
//   yawZeroAdj:0x08
// }
// var viewlink_A2_fdctr ={
//  disable:0,
//  enable:1
// }
// var viewlink_A2_framecount ={
//  counter:0
// }
// var viewlink_A2_param ={
//  param1:0x00
// }
// var A2_CMD ={
//  servo: viewlink_A2_servo.noAction,
//  fdctr:viewlink_A2_fdctr.enable,
//  framecounter:viewlink_A2_framecount.counter,
//  param1:viewlink_A2_param.param1
// }
var C1_VIDEO_SOURCE = {
  noaction: 0,
  EO: 1,
  IR: 2,
  EOir: 3,
  IReo: 4,
  EO2: 5,
  fusion: 6
}

var C1_ZOOM_SPEED = {
  zoomspeed1: 1,
  zoomspeed2: 2,
  zoomspeed3: 3,
  zoomspeed4: 4,
  zoomspeed5: 5,
  zoomspeed6: 6,
  zoomspeed7: 7
}

var C1_CAM_CTR = {
  noaction: 0x0000,
  stopfocus: 0x0001,
  brightup: 0x0002,
  brightdown: 3,
  zoomout: 8,
  zoomin: 9,
  focusout: 0x0a,
  focusin: 0x0b,
  whitehot: 0x0e,
  blackhot: 0x0f,
  colorhot: 0x12,
  takepicture: 0x13,
  startrecord: 0x14,
  stoprecord: 0x15,
  topicturemode: 0x16,
  torecordmode: 0x17,
  invertmode: 0x18,
  afon: 0x19,
  afoff: 0x1a,
  irdzoomin: 0x1b,
  irdzoomout: 0x1c,
  sdcardformat: 0x1d
}
var C1_LRF = {
  noaction: 0,
  onetimeLRF: 1,
  LRFstart: 2,
  LRFstart2: 3,
  outsynLRF: 4,
  stopLRF: 5
}
var C1_CMD = {
  videosource: C1_VIDEO_SOURCE.noaction,
  zoom_speed: C1_ZOOM_SPEED.speed,
  camera_ctrl: C1_CAM_CTR.noaction,
  LRFctr: C1_LRF.noaction
}
var C2_ctrl = {
  noaction: 0,
  eodzoomon: 6,
  eodzoomoff: 7,
  eoVEon: 0x10,
  eoVEoff: 0x11,
  ircolorbaron: 0x12,
  ircolorbaroff: 0x13,
  eoflipoff: 0x14,
  eoflipon: 0x15,
  defogon: 0x16,
  defogoff: 0x17,
  osdon: 0x18,
  osdoff: 0x19,
  irflipoff: 0x1a,
  irflipon: 0x1b,
  eoICRon: 0x4a,
  eoICRoff: 0x4b,
  eozoomto: 0x53,
  laserctrl: 0x74
}
var C2_laser_cmd = {
  noaction: 0,
  laseron: 0X0100,
  laseroff: 0X0200,
  laserzoomout: 0X0400,
  laserzoomin: 0X0500,
  synauto: 0X0600,
  manulaser: 0X0700

}
var C2_CMD = {
  ctrl: C2_ctrl.noaction,
  param: C2_laser_cmd.noaction
}
var E1_tracksource = {
  noaction: 0,
  eo1: 1,
  ir: 2,
  eo2: 3
}
var E1_param1 = {
  param1: 0x00
}
var E1_ctrl = {
  noaction: 0,
  stoptrack: 1,
  searchobject: 2,
  starttrack: 3,
  re_track: 4,
  smalltemplate: 0x21,
  middletemplate: 0x22,
  bigtemplate: 0x23,
  midsmalltemplate: 0x24,
  bigmidtemplate: 0x26,
  autotemplate: 0x28
}

var E1_param2 = {
  param2: 0x00
}
var E1_CMD = {
  tracksource: E1_tracksource.noaction,
  param1: A1_PARAM_INT16.PARAM1,
  ctrl: E1_ctrl.noaction,
  param2: E1_param2.param2
}
var E2_ctr = {
  noaction: 0,
  fingertracktopos: 0x0a
}
// var E2_CMD ={
//   ctr:E2_ctr.noaction,
//   param1:0x0000,
//   param2:0x0000

// }
export function viewlink_checksum(array) {
  var checksum = array[3];
  var i = 4;
  var len = (array[3] & 0x3f) - 2;
  while (len--) {
    checksum = checksum ^ array[i];
    i++;
  }
  return checksum;
}
export function pack_FW(data, len) {
  var FW_data_array = data; //9
  var FWpack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];

  var viewlink_cmdLEN_FW = len + 3;
  var viewlinkFrameLen_FW = viewlink_cmdLEN_FW + viewlinkheadlen;
  var viewlink_cs_pos_FW = viewlinkFrameLen_FW - 1;

  FWpack_array[3] = viewlink_cmdLEN_FW;
  FWpack_array[4] = viewlink_cmdID.FW;
  FW_data_array.forEach(function (item) {
    FWpack_array.push(item)
  })
  FWpack_array[viewlink_cs_pos_FW] = viewlink_checksum(FWpack_array);
  return FWpack_array;
}
export function C2_to_array(C2_CMD) {
  var array = new Array();
  array[0] = C2_CMD.ctrl;
  array[1] = C2_CMD.param >> 8;
  array[2] = C2_CMD.param & 0XFF;
  return array;
}
export function pack_C1(C1CMD) {
  var C1_data_array = C1_to_array(C1CMD); //9
  var C1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  C1pack_array[3] = viewlink_cmdLEN.C1;
  C1pack_array[4] = viewlink_cmdID.C1;
  C1_data_array.forEach(function (item) {
    C1pack_array.push(item)
  })
  C1pack_array[viewlink_cs_pos.C1] = viewlink_checksum(C1pack_array);
  return C1pack_array;
}
export function C1_to_array(C1_CMD) {
  var array = new Array();
  var temp = 0x0000;
  temp = (C1_CMD.videosource & 0x07) | ((C1_CMD.zoom_speed & 0x07) << 3) |
    ((C1_CMD.camera_ctrl & 0x007f) << 6) | ((C1_CMD.LRFctr & 0x07) << 13);
  array[0] = (temp >> 8) & 0xff;
  array[1] = temp & 0xff;
  return array;
}
export function pack_C2(C2_CMD) {
  var C2_data_array = C2_to_array(C2_CMD); //9
  var C2pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  C2pack_array[3] = viewlink_cmdLEN.C2;
  C2pack_array[4] = viewlink_cmdID.C2;
  C2_data_array.forEach(function (item) {
    C2pack_array.push(item)
  })
  C2pack_array[viewlink_cs_pos.C2] = viewlink_checksum(C2pack_array);
  return C2pack_array;
}
export function A1_to_array(A1_CMD) {
  var array = new Array();
  array[0] = A1_CMD.server_status;

  array[1] = A1_CMD.param1 >> 8;
  array[2] = A1_CMD.param1 & 0xff;

  array[3] = A1_CMD.param2 >> 8;
  array[4] = A1_CMD.param2 & 0xff;

  array[5] = A1_CMD.param3 >> 8;
  array[6] = A1_CMD.param3 & 0xff;

  array[7] = A1_CMD.param4 >> 8;
  array[8] = A1_CMD.param4 & 0xff;

  return array;
}
export function pack_A1(A1CMD) {
  var A1_data_array = A1_to_array(A1CMD); //9
  var A1pack_array = [0x55, 0xaa, 0xdc, 0X00, 0X00];
  A1pack_array[3] = viewlink_cmdLEN.A1; //12
  A1pack_array[4] = viewlink_cmdID.A1;

  A1_data_array.forEach(function (item) { // 将A1CMD的数据提取出，拼接在A1pack_array
    A1pack_array.push(item)
  })

  A1pack_array[viewlink_cs_pos.A1] = viewlink_checksum(A1pack_array);
  return A1pack_array;
}
export function cal_crc_table(array) {
  var crc = 0;
  var i = 1;
  var len = array[2];
  while (len--) {
    crc = crc_table[crc ^ array[i]];
    i++;
  }
  return crc;
}
export function package_send(buff, len) {
  var i = 0;
  var j = 0;
  var count = 0;
  // var alen = len;
  var hexbuf = [];
  var sendbuf = [];


  if (len % 48 == 0) {
    count = parseInt(len / 48, 10);
  } else {
    count = parseInt(len / 48, 10) + 1;
  }

  for (i = 0; i < count; i++) {
    if ((count - i) == 1) {
      for (j = 0; j < len - i * 48; j++) {
        sendbuf[j] = buff[i * 48 + j];
        hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
      }
      // console.log("hexbuf:" + hexbuf.toString(16));
      // webSocket.send(sendbuf);
      // console.log(sendbuf, 'send')
      return sendbuf
    } else {

      for (j = 0; j < 48; j++) {
        sendbuf[j] = buff[i * 48 + j];
        hexbuf[j] = Number(buff[i * 48 + j]).toString(16);
      }
      // console.log("hexbuf:" + hexbuf.toString(16));
      // webSocket.send(sendbuf);
      // console.log(sendbuf)
      return sendbuf
    }
  }
  // if(len-48 >=0){
  //    for(count = 0;(alen-=48)>=0;count+=48){
  //        for(i = 0; i < 48; i++){
  //            abuff[i]=buff[i+count];
  //        }
  //        console.log("abbuf:" + abuff);
  //        webSocket.send(abuff);
  //    }
  // }
  // if(len%48!=0){
  //     for(i=0;i<(len%48);i++){
  //         bbuff[i]=buff[parseInt(len/48)*48+i];
  //     }
  //     console.log("abbuf:" + bbuff);
  //     webSocket.send(bbuff);
  // }

}
export const MMC_Gimbal_Z30Pro = {
  give_directions_ctrl(fingermode) { // 指点跟踪0，指点移动1
    // console.log("give_directions_ctrl:" + fingermode);
    var buff = new Array(0xA5, 0x4f, 0x00);

    finger_mode = fingermode;

    if (fingermode == 0) { // MOVE
      A1_CMD.server_status = A1_SERVO_STATUS.noaction
    } else if (fingermode == 1) { //TRACK  or focus
      A1_CMD.server_status = A1_SERVO_STATUS.noaction
    } else if (fingermode == 2) { // SPOT AE
      A1_CMD.server_status = A1_SERVO_STATUS.noaction
    }
    return buff
  },
  gimbal_mode_ctrl(mode) {
    // console.log("gimbal_mode_ctrl:" + mode); // 0 follow disable 1 follow enable 2 homeposition
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    if (mode == 0) {
      A1_CMD.server_status = A1_SERVO_STATUS.lockyaw;
    } else if (mode == 1) {
      A1_CMD.server_status = A1_SERVO_STATUS.followyaw;
    } else if (mode == 2) {
      A1_CMD.server_status = A1_SERVO_STATUS.homeposition;
    }
    var buff_arr = pack_A1(A1_CMD);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })

    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    // console.log(mmc_cs_pos.A1, 'mmc_cs_pos.A1')
    let buffer = package_send(buff, mmcFrameLen.A1);
    gimbal_mode = mode;
    return buffer
  },
  camera_WD_ctrl(cameraWD) { //
    // console.log("camera_WD_ctrl" + cameraWD);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x3d, 0x02, 0xff];
    if (cameraWD == 1)
      FW_CMD_data[4] = 2;
    else
      FW_CMD_data[4] = 3;

    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos_FW] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen_FW);
    return buffer
  },
  camera_Defog_ctrl(cameraDefog) { //
    // console.log("camera_Defog_ctrl" + cameraDefog);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 7;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x37, 0x02, 0x00, 0xff];
    if (cameraDefog == 1) {
      FW_CMD_data[4] = 2;
      FW_CMD_data[5] = 3;
    } else {
      FW_CMD_data[4] = 3;
      FW_CMD_data[5] = 0;
    }

    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos_FW] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen_FW);
    return buffer
  },
  stabilizer_mode_ctrl(stabilizer_mode) { //
    // console.log("stabilize_mode" + stabilizer_mode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x34, 0x02, 0xff];
    if (stabilizer_mode == 0) {
      FW_CMD_data[4] = 3;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (stabilizer_mode == 1) {
      FW_CMD_data[4] = 2;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      package_send(buff, mmcFrameLen_FW);

      var buff1 = new Array(0xA5, 0x4f, 0x00);
      FW_data_len = 7;
      var FW2_CMD_data = [0x81, 0x01, 0x7e, 0x04, 0x34, 0x02, 0xff];
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff1[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW2_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff1.push(item)
      })
      buff1[mmc_cs_pos_FW] = cal_crc_table(buff1);
      package_send(buff1, mmcFrameLen_FW);
    } else if (stabilizer_mode == 2) {
      FW_data_len = 7;
      var FW3_CMD_data = [0x81, 0x01, 0x7e, 0x04, 0x34, 0x03, 0xff];
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW3_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    }
  },
  VE_mode_ctrl(VEmode) { //
    // console.log("VE_mode_ctrl" + VEmode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x3D, 0x06, 0xff];
    if (VEmode == 0) {
      FW_CMD_data[4] = 3;
    }

    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos_FW] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen_FW);
    return buffer
  },
  NR_mode_ctrl(NRmode) { //
    // console.log("NR_mode_ctrl" + NRmode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x53, 0x05, 0xff];
    if (NRmode == 0) {
      FW_CMD_data[4] = 0;
    }

    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos_FW] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen_FW);
    return buffer
  },
  DZOOM_mode_ctrl(DZOOMmode) { //
    // console.log("DZOOM_mode_ctrl" + DZOOMmode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x06, 0x02, 0xff];
    if (DZOOMmode == 0) {
      FW_CMD_data[4] = 3;
    }

    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos_FW] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen_FW);
    return buffer
  },
  videooutput_mode_ctrl(videooutput_mode) { //
    // console.log("videooutput_mode_ctrl" + videooutput_mode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 8;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x24, 0x72, 0x02, 0x08, 0xff]; //81 01 04 24 72 00 04 ff 
    if (videooutput_mode == 0) {
      FW_CMD_data[5] = 1;
      FW_CMD_data[6] = 5;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (videooutput_mode == 1) {
      FW_CMD_data[5] = 0;
      FW_CMD_data[6] = 7;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (videooutput_mode == 2) {
      FW_CMD_data[5] = 2;
      FW_CMD_data[6] = 8;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    }
    //8x 01 04 3F 01 7F FF
    var buff1 = new Array(0xA5, 0x4f, 0x00);
    var FW1_CMD_data = [0x81, 0x01, 0x04, 0x3f, 0x01, 0x7f, 0xff];
    FW_data_len = 7;
    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff1[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW1_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff1.push(item)
    })
    buff1[mmc_cs_pos_FW] = cal_crc_table(buff1);
    package_send(buff1, mmcFrameLen_FW);
    //8x 01 04 19 03 FF Camera reset
    var buff2 = new Array(0xA5, 0x4f, 0x00);
    var FW2_CMD_data = [0x81, 0x01, 0x04, 0x19, 0x03, 0xff];
    FW_data_len = 6;
    mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
    mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
    mmc_cs_pos_FW = mmcFrameLen_FW - 1;

    buff2[2] = mmcCmdLen_FW;

    var buff_arr = pack_FW(FW2_CMD_data, FW_data_len);
    buff_arr.forEach(function (item) {
      buff2.push(item)
    })
    buff2[mmc_cs_pos_FW] = cal_crc_table(buff2);
    package_send(buff2, mmcFrameLen_FW);
  },
  ICR_mode_ctrl(icrmode) { //
    console.log("ICR_mode_ctrl" + icrmode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.C2;
    if (icrmode == 2) {
      FW_data_len = 6;
      var FW_CMD_data = [0x81, 0x01, 0x04, 0x51, 0x04, 0xff]; //8x 01 04 51 04 FF
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (icrmode == 1) {
      FW_data_len = 6;
      var FW_CMD_data = [0x81, 0x01, 0x04, 0x51, 0x03, 0xff]; //8x 01 04 51 03 FF
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      package_send(buff, mmcFrameLen_FW);

      C2_CMD.ctrl = C2_ctrl.eoICRon;
      var buff_arr = pack_C2(C2_CMD);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos.C2] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen.C2);
      return buffer
    } else if (icrmode == 0) {
      FW_data_len = 6;
      var FW_CMD_data = [0x81, 0x01, 0x04, 0x51, 0x03, 0xff]; //8x 01 04 51 03 FF
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      package_send(buff, mmcFrameLen_FW);
      C2_CMD.ctrl = C2_ctrl.eoICRoff;
      var buff_arr = pack_C2(C2_CMD);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos.C2] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen.C2);
      return buffer
    }

  },
  WB_mode_ctrl(WB_mode) { //
    // console.log("WB_mode_ctrl" + WB_mode);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x35, 0x00, 0xff]; //8x 01 04 35 00 FF
    if (WB_mode == 0) {
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (WB_mode == 1) {
      FW_CMD_data[4] = 1;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (WB_mode == 2) {
      FW_CMD_data[4] = 2;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    }
  },
  zoom(zoom_ctrl) {
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.C1;
    C1_CMD.zoom_speed = C1_ZOOM_SPEED.zoomspeed7;

    if (zoom_ctrl == 0) {
      C1_CMD.camera_ctrl = C1_CAM_CTR.zoomout;
    } else if (zoom_ctrl == 1) {
      C1_CMD.camera_ctrl = C1_CAM_CTR.stopfocus;
    } else if (zoom_ctrl == 2) {
      C1_CMD.camera_ctrl = C1_CAM_CTR.zoomin;
    }
    var buff_arr = pack_C1(C1_CMD);

    buff_arr.forEach(function (item) {
      buff.push(item)
    })

    buff[mmc_cs_pos.C1] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen.C1);
    return buffer
  },
  saturated(gain_ctrl) {
    // console.log("camgain:" + gain_ctrl);
    var buff = new Array(0xA5, 0x4f, 0x00);
    FW_data_len = 6;
    var FW_CMD_data = [0x81, 0x01, 0x04, 0x0c, 0x02, 0xff]; //8x 01 04 0C 02 FF

    if (gain_ctrl == 0) {
      FW_CMD_data[4] = 3;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;

      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    } else if (gain_ctrl == 2) {
      FW_CMD_data[4] = 2;
      mmcCmdLen_FW = FW_data_len + 3 + viewlinkheadlen + mmcAddLen;
      mmcFrameLen_FW = mmcCmdLen_FW + mmcHeadLen;
      mmc_cs_pos_FW = mmcFrameLen_FW - 1;

      buff[2] = mmcCmdLen_FW;
      var buff_arr = pack_FW(FW_CMD_data, FW_data_len);
      buff_arr.forEach(function (item) {
        buff.push(item)
      })
      buff[mmc_cs_pos_FW] = cal_crc_table(buff);
      let buffer = package_send(buff, mmcFrameLen_FW);
      return buffer
    }
  },
  change_pitch_angle(angle) {
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD.server_status = A1_SERVO_STATUS.turntoframeangle;
    var pitchangle = 0;
    if (angle >= 0) {
      pitchangle = angle;
      A1_CMD.param2 = pitchangle * 65536 / 360;
      // console.log("gimbal_pitch_angle:" + pitchangle); // up 3  down 253 -3
    } else {
      pitchangle = angle;
      A1_CMD.param2 = pitchangle * 65536 / 360 + 65536;
      // console.log("gimbal_pitch_angle:" + pitchangle); // up 3  down 253 -3
    }
    var buff_arr = pack_A1(A1_CMD);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen.A1);
    return buffer
  },
  change_yaw_angle(angle) {
    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD.server_status = A1_SERVO_STATUS.turntoframeangle;
    var yawangle = 0;
    if (angle >= 0) {
      yawangle = angle;
      A1_CMD.param1 = yawangle * 65536 / 360;
      // console.log("gimbal_yaw_angle:" + yawangle); // up 3  down 253 -3
    } else {
      yawangle = angle;
      A1_CMD.param1 = yawangle * 65536 / 360 + 65536;
      // console.log("gimbal_yaw_angle:" + yawangle); // up 3  down 253 -3
    }
    var buff_arr = pack_A1(A1_CMD);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen.A1);
    return buffer
  },
  gimbal_pitch_ctrl(speed, con_speed) { // speed 1 -1  0
    var realspeed = ((speed * con_speed) >>> 0) & 0xff; // 转换为正，-3 转换为无符号253  -1 转为 255
    var pitchspeed = 0;

    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD.server_status = A1_SERVO_STATUS.manualcontrol;

    if (realspeed < 100) {
      pitchspeed = realspeed; // 向上
      A1_CMD.param2 = pitchspeed * 100;
      // console.log("gimbal_pitch_ctrl:" + realspeed); // up 3  down 253 
    } else {
      pitchspeed = realspeed - 256;
      A1_CMD.param2 = pitchspeed * 100 + 65536;
      // console.log("gimbal_pitch_ctrl:" + pitchspeed); // up 3  down 253 
    }
    var buff_arr = pack_A1(A1_CMD);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    let buffer = package_send(buff, mmcFrameLen.A1);
    if (speed == 0) {
      pitch_move = false;
    } else {
      pitch_move = true;
    }
    return buffer
  },
  control_mode_ctrl(mode) {
    var buff = new Array(0xA6, 0x01, 0x03, 0x00, 0x00);
    buff[3] = mode;
    buff[4] = cal_crc_table(buff);
    return buff
  },
  gimbal_yaw_ctrl(speed, con_speed) {

    var realspeed = ((speed * con_speed) >>> 0) & 0xff;
    var yawspeed = 10;

    var buff = new Array(0xA5, 0x4f, 0x00);
    buff[2] = mmcCmdLen.A1;
    A1_CMD.server_status = A1_SERVO_STATUS.manualcontrol;
    if (realspeed < 100) {
      yawspeed = realspeed;
      A1_CMD.param1 = yawspeed * 100;
      // console.log("gimbal_yaw_ctrl:" + yawspeed); // up 3  down 253 -3
    } else {
      yawspeed = realspeed - 256;
      A1_CMD.param1 = yawspeed * 100 + 65536;
      // console.log("gimbal_yaw_ctrl:" + yawspeed); // up 3  down 253 -3
    }

    var buff_arr = pack_A1(A1_CMD);
    buff_arr.forEach(function (item) {
      buff.push(item)
    })
    buff[mmc_cs_pos.A1] = cal_crc_table(buff);
    let buffer = package_send(buff, buff[2] + 2);

    if (speed == 0) {
      yaw_move = false;
    } else {
      yaw_move = true;
    }
    return buffer
  },
  /**
     * 拍照
     * @param {*} param0 
     * @returns 
     */
   take_photo({ longitude = 0, latitude = 0, altitude = 0 }) {
    var buff = new Array(0xA5, 0x01, 16, /*index*/ 0x00, 0x00, /*altitude*/ 0x00, 0x00, 0x00, 0x00,
        /*latitude*/
        0x00, 0x00, 0x00, 0x00, /*longitude*/ 0x00, 0x00, 0x00, 0x00, 0x00);

    buff[5] = (altitude) & 0xff;
    buff[6] = (altitude >> 8) & 0xff;
    buff[7] = (altitude >> 16) & 0xff;
    buff[8] = (altitude >> 24) & 0xff;

    buff[9] = (latitude) & 0xff;
    buff[10] = (latitude >> 8) & 0xff;
    buff[11] = (latitude >> 16) & 0xff;
    buff[12] = (latitude >> 24) & 0xff;

    buff[13] = (longitude) & 0xff;
    buff[14] = (longitude >> 8) & 0xff;
    buff[15] = (longitude >> 16) & 0xff;
    buff[16] = (longitude >> 24) & 0xff;

    buff[17] = cal_crc_table(buff);
    return buff
},
/**
 * 
 * @param {*} status: 1 开始录像, 0 停止录像
 */
record(status) {
    var buff = new Array(0xA5, 0x05, 0x03, 0x00, 0x00);
    if (status) {
        buff[3] = 2;
    } else {
        buff[3] = 0;
    }
    buff[4] = cal_crc_table(buff);
    return buff
},
}