<template>
  <div class="cpt-mount cpt-MMC_Gimbal_Z30Pro" :style="containerStyle">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div class="speed" :style="control_speed == index + 1
                ? 'background: #FFFFFF;color: #333333;'
                : ''
                " v-for="(item, index) in new Array(5).fill(1)" :key="index" @click="control_speed = index + 1">
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio size="mini" v-for="item in drd.ptz_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <el-button
              @click="handle_zoom(1)"
              size="mini"
              icon="el-icon-plus"
              circle
            ></el-button>
            <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button>
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;倍
          </div>
          <div class="input-box">
            <div class="mr10" @mousedown="handle_zoom(-1)" @mouseup="stopChange" @mouseover="stopChange">
              <span class="icon el-icon-remove-outline"></span>
            </div>
            <el-slider v-model="value" :max="50" :min="0" :show-tooltip="false" style="width: 180px" @change="stopChange"
              @input="onChangezoom"></el-slider>
            <div class="ml10" @mousedown="handle_zoom(1)" @mouseup="stopChange" @mouseover="stopChange">
              <span class="icon el-icon-circle-plus-outline"></span>
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input-number
              v-model.number="pitch"
              size="mini"
              placeholder="请输入俯仰"
            />
            <el-button @click="handle_change_pitch" size="mini" type="primary"
              >设置</el-button
            >
          </div>
        </div> -->
        <!-- <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input-number
              v-model.number="yaw"
              size="mini"
              placeholder="请输入俯仰"
            />
            <el-button @click="handle_change_yaw" size="mini" type="primary"
              >设置</el-button
            >
          </div>
        </div> -->
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input style="width: 120px" v-model.number="pitch" size="mini" placeholder="请输入俯仰" />
            <span class="icon el-icon-remove-outline" @click="pitch--"></span>
            <span class="icon el-icon-circle-plus-outline" @click="pitch++"></span>
            <el-button style="
                background: linear-gradient(
                  180deg,
                  rgba(28, 91, 197, 0.69) 0%,
                  #091a38 100%
                );
                opacity: 0.8;
                border: 1px solid #488cff;
                color: #9fc4ff;
              " @click="handle_change_pitch" size="mini" type="primary">设置</el-button>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input style="width: 120px" v-model.number="yaw" size="mini" placeholder="请输入航向" />
            <span class="icon el-icon-remove-outline" @click="yaw--"></span>
            <span class="icon el-icon-circle-plus-outline" @click="yaw++"></span>
            <el-button style="
                background: linear-gradient(
                  180deg,
                  rgba(28, 91, 197, 0.69) 0%,
                  #091a38 100%
                );
                opacity: 0.8;
                border: 1px solid #488cff;
                color: #9fc4ff;
              " @click="handle_change_yaw" size="mini" type="primary">设置</el-button>
          </div>
        </div>

        <div class="form-item">
          <div class="label-box">可见度增强</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_VE_mode" v-model="VE_mode">
              <el-radio size="mini" v-for="item in drd.VE_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">降噪</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_NR_mode" v-model="NR_mode">
              <el-radio size="mini" v-for="item in drd.NR_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">宽动态</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_camera_WD" v-model="camera_WD">
              <el-radio size="mini" v-for="item in drd.camera_WD" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子变倍</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_DZOOM_mode" v-model="DZOOM_mode">
              <el-radio size="mini" v-for="item in drd.DZOOM_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">电子增稳</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_stabilizer_mode" v-model="stabilizer_mode">
              <el-radio size="mini" v-for="item in drd.stabilizer_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">视频输出</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_videooutput_mode" v-model="videooutput_mode">
              <el-radio size="mini" v-for="item in drd.videooutput_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">夜间模式</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_ICR_mode" v-model="ICR_mode">
              <el-radio size="mini" v-for="item in drd.ICR_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">白平衡</div>
          <div class="input-box">
            <el-radio-group @change="handle_change_WB_mode" v-model="WB_mode">
              <el-radio size="mini" v-for="item in drd.WB_mode" :key="item.id" :label="item.id">{{ item.label
              }}</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="item-group">
          <div @click="handle_take_photo" class="item-box">
            <el-tooltip class="item" effect="dark" content="拍照" placement="top">
              <div class="icon-box">
                <span class="el-icon-camera"></span>
              </div>
            </el-tooltip>
          </div>
          <div @click="handle_record" class="item-box">
            <el-tooltip class="item" effect="dark" content="录像" placement="top">
              <div class="icon-box">
                <span class="el-icon-video-camera"></span>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { cal_crc_table, MMC_Gimbal_Z30Pro } from "./js/index";
import { MMC_Gimbal_Z40 } from "../utils";

export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      radio: 1,

      control_speed: 3,
      ptz_mode: 0,
      zoom: 1,
      pitch: 0,
      yaw: 0,

      VE_mode: 0,
      NR_mode: 0,
      camera_WD: 0,
      DZOOM_mode: 0,
      stabilizer_mode: 0,
      videooutput_mode: 0,
      ICR_mode: 0,
      WB_mode: 0,

      record: false,
      drd: {
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        VE_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        NR_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        camera_WD: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        DZOOM_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        stabilizer_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "高级" },
          { id: 2, label: "超级" },
        ],
        videooutput_mode: [
          { id: 0, label: "1080p60" },
          { id: 1, label: "1080p30" },
          { id: 2, label: "2k30" },
        ],
        ICR_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "黑白" },
          { id: 2, label: "彩色" },
        ],
        WB_mode: [
          { id: 0, label: "室内" },
          { id: 1, label: "自动" },
          { id: 2, label: "室外" },
        ],
      },

      timer: null,
      buffer: null,
      value: 25,
      value2: 25,
    };
  },
  watch: {
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  methods: {
    resolve_payload(buff) {
      let dataArray = new Uint8Array(buff);
      if (
        dataArray[0] === 0xa5 &&
        cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
      ) {
        switch (dataArray[1]) {
          case 0x01:
            this.msg_key_status1(dataArray);
            break;
          case 0xf8:
            this.gcs_button_ctrl(dataArray);
            break;
        }
      }
    },
    onChangezoom(e) {
      if (e < 25) {
        this.handle_zoom(-1);
      } else if (e > 25) {
        this.handle_zoom(1);
      } else {
        this.handle_zoom(2);

      }
      // 
      this.value2 = e;
    },
    stopChange() {
      this.value = 25;
      this.handle_zoom(2);
    },
    gcs_button_ctrl(data) {
      let button = data[3];
      let status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },

    handle_change_ptz_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      // console.log(value, 'value');
      if (value == -1) {
        this.commit_directive([165, 79, 10, 85, 170, 220, 5, 28, 2, 56, 35, 6])
      } else if (value == 1) {
        this.commit_directive([165, 79, 10, 85, 170, 220, 5, 28, 2, 120, 99, 178])
      } else {
        this.commit_directive([165, 79, 10, 85, 170, 220, 5, 28, 0, 120, 97, 92])
      }

    },
    handle_change_pitch() {
      let buffer = MMC_Gimbal_Z30Pro.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      let buffer = MMC_Gimbal_Z30Pro.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },

    handle_change_VE_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.VE_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_NR_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.NR_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_camera_WD(value) {
      let buffer = MMC_Gimbal_Z30Pro.camera_WD_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_DZOOM_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.DZOOM_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_stabilizer_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.stabilizer_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_videooutput_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.videooutput_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_ICR_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.ICR_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },
    handle_change_WB_mode(value) {
      let buffer = MMC_Gimbal_Z30Pro.WB_mode_ctrl(value);
      if (buffer[0] && buffer[0] instanceof Array) {
        buffer.forEach((item) => this.commit_directive(item));
      } else {
        this.commit_directive(buffer);
      }
    },

    handle_take_photo() {
      let { position } = this;
      let buffer = MMC_Gimbal_Z30Pro.take_photo(position || {});
      this.commit_directive(buffer);
    },
    handle_record() {
      this.record = !this.record;
      let buffer = MMC_Gimbal_Z30Pro.record(this.record);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
      // console.log(buffer);
    },
    init_rocker() {
      const joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              case "right":
                this.buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(1, this.control_speed);
                break;
              case "left":
                this.buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(
                  -1,
                  this.control_speed
                );
                break;
              case "down":
                this.buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_Z30Pro.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);
      this.buffer = MMC_Gimbal_Z30Pro.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>